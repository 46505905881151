export const setForm = {
	enableFirstLoginSetting: undefined,
	amount: undefined,
	enableSignSetting: undefined,
	signType: 1,
	signBeginTime: undefined,
	signEndTime: undefined,
	signReward: 1,
	signName: undefined,
	signRewardSetting: undefined,
}