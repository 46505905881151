<template>
  <div>
    <div class="cardTitle">任务设置</div>
    <a-form-model
      class="form"
      :model="setForm"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <div class="title">首次登录设置</div>
      <a-form-model-item label="是否开启" prop="enableFirstLoginSetting">
        <a-radio-group
          v-model="setForm.enableFirstLoginSetting"
          button-style="solid"
        >
          <a-radio-button :value="true"> 开启 </a-radio-button>
          <a-radio-button :value="false"> 关闭 </a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="获取积分数额" prop="amount">
        <a-input-number
          v-model.number="setForm.amount"
          :disabled="setForm.enableFirstLoginSetting === false"
        ></a-input-number>
      </a-form-model-item>
      <div class="title">签到奖励设置</div>
      <a-form-model-item label="是否开启签到" prop="enableSignSetting">
        <a-radio-group v-model="setForm.enableSignSetting" button-style="solid">
          <a-radio-button :value="true"> 开启 </a-radio-button>
          <a-radio-button :value="false"> 关闭 </a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="签到方式" prop="signType">
        <a-space>
          <!-- <span :class="[setForm.enableSignSetting === false ? 'disabled' : '']">
            {{ setForm.signType === 1 ? "一周每日签到模式" : "" }}
          </span> -->
          <a-radio-group v-model="setForm.signType" button-style="solid">
            <a-radio-button :value="1"> 一周每日签到方式 </a-radio-button>
          </a-radio-group>
          <a-popover>
            <template slot="content">
              <p style="width: 300px">
                一周签到模式：在后台设置每周累计1天至
                累计7天不同的签到积分奖励，用户签到时按照
                实际累计登陆天数签到。例：A用户周一签到，
                周二缺签，周三签到时可领取累计2天的签到奖励，
                每周一的凌晨00:00将会重置所有累计签到次数，
                用户将由第一天起重新签到。
              </p>
            </template>
            <a-icon type="question-circle" />
          </a-popover>
        </a-space>
      </a-form-model-item>
      <a-form-model-item label="活动时间" prop="signTime">
        <a-range-picker
          :disabled="setForm.enableSignSetting === false"
          style="width: 400px"
          @change="changeTime"
          v-model="time"
          value-format="YYYY-MM-DD HH:mm:ss"
        />
      </a-form-model-item>
      <a-form-model-item label="签到奖励" prop="signReward">
        <a-radio-group v-model="setForm.signReward" button-style="solid">
            <a-radio-button :value="1"> 商城积分 </a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="签到活动名称" prop="signName">
        <a-input
          v-model="setForm.signName"
          :disabled="setForm.enableSignSetting === false"
          style="width: 300px"
        ></a-input>
      </a-form-model-item>
      <a-form-model-item label="签到奖励设置" prop="signRewardSetting">
        <div v-for="(item, index) in dailyScore" :key="index">
          <a-space>
            {{ item.label}}
            <a-input
              type="number"
              :disabled="setForm.enableSignSetting === false"
              v-model.number="dailyScore[index].value"
              addon-after="分"
              style="width: 100px"
            ></a-input>
          </a-space>
        </div>
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
        <a-button type="primary" @click="onSubmit"> 保存 </a-button>
        <a-button style="margin-left: 10px" @click="getData"> 重置 </a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
import { setForm } from "./depend/config";
import { getSetting, Setting } from "@/api/shop/score";
export default {
	data() {
		return {
			setForm,
			labelCol: { span: 4 },
			wrapperCol: { span: 20 },
			time: [],
			dailyScore: [
				{
					label: "第一天",
					value: 0,
				},
				{
					label: "第二天",
					value: 0,
				},
				{
					label: "第三天",
					value: 0,
				},
				{
					label: "第四天",
					value: 0,
				},
				{
					label: "第五天",
					value: 0,
				},
				{
					label: "第六天",
					value: 0,
				},
				{
					label: "第七天",
					value: 0,
				},
			],
		};
	},
	mounted() {
		this.getData();
	},
	methods: {
		async getData() {
			let res = await getSetting();
			this.setForm = res.data;
      res.data.signRewardSetting.split(",").forEach((ele, index) => {
        this.dailyScore[index].value = ele;
      });
			this.time[0] = res.data.signBeginTime;
			this.time[1] = res.data.signEndTime;
		},
		async onSubmit() {
      let reward = '';
      for(let i=0;i<this.dailyScore.length;i++) {
        if(i == this.dailyScore.length - 1) {
          reward = reward + this.dailyScore[i].value
        } else {
          reward = reward + this.dailyScore[i].value + ','
        }
      }
      this.setForm.signRewardSetting = reward;
			let res = await Setting(this.setForm);
			if (res.code === 200) {
				this.$message.success(res.msg);
				this.getData();
			} else {
				this.$message.error(res.msg);
			}
		},
		changeTime(date) {
			this.setForm.signBeginTime = date[0];
			this.setForm.signEndTime = date[1];
		},
	},
};
</script>

<style lang="less" scoped>
/deep/.ant-input {
  display: inline;
}
.disabled {
  color: #00000040;
}
.title {
  color: #000;
  font-size: 15px;
  font-weight: 500;
  padding-left: 140px;
}
</style>
