import httpService from "@/request"

// 获取任务设置
export function getSetting(params) {
	return httpService({
		url: `/user/pointsSetting/earnPointsSettingInfo`,
		method: 'get',
		params: params,
	})
}
// 保存任务设置
export function Setting(params) {
	return httpService({
		url: `/user/pointsSetting/savePintsSetting`,
		method: 'post',
		data: params,
	})
}